import "./styles.css";

import Form from "../../components/Form";
import CtaTel from "../../components/CTATel";

const FormPage = () => {
  return (
    <section id="formPage">
      <article className="formPage1">
        <div className="container vertical">
          <h2 className="title">Get a Free Estimates</h2>

          <p className="text">Get a quote</p>

          <Form />
        </div>
      </article>

      <article className="formPage2">
        <div className="container vertical">
          <h2 className="title">Call Us Today!</h2>

          <div className="items">
            <div className="item">
              <CtaTel text={"(504) 305 0325"} />

              <p className="text">NEW ORLEANS, LA.</p>
            </div>

            <div className="item">
              <CtaTel text={"(850) 738 5188"} tel={"tel:850 738 5188"} />

              <p className="text">DESTIN, FL.</p>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default FormPage;
