import "./styles.css";

import CtaTel from "../../components/CTATel";
import CtaForm from "../../components/CTAForm";

import header3 from "../../assets/header3.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h1 className="title">
            <span>We are Interior and exterior Experts</span> <br />
            JL Remodeling
          </h1>

          <p className="text">
            Gutters in New Orleans and Destin FL. We are a company expert in
            Gutter Installation and many more, with more than 18 years of
            experience. We are a verified company, our work is guaranteed and we
            offer Best Prices. <br />
            <b>We are licensed & insured in Louisiana and Florida.</b>
          </p>

          <CtaTel />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h2 className="title">
            We serve New Orleans, Metairie, Kenner, Laplace, Luling, Slidell,
            Mandeville and surrounding areas.
          </h2>
        </article>
      </section>

      <section className="header3">
        <article className="container">
          <div className="left">
            <p className="text1">WELCOME TO JL REMODELING</p>

            <h2 className="title">We specialize in home exterior services.</h2>

            <figure className="mobile">
              <img src={header3} alt="Casas" />
            </figure>

            <p className="text2">
              At JL Remodeling, we're committed to making your home look great.
              Our expertise includes various services such as:Siding, soffit,
              fascia, gutters, gutters installation, gutter covers, patio
              covers, and roofing. Our prices are fair and fit your budget. We
              make sure to finish the work on time.
              <br />
              <br />
              You can trust us to do a good job with any home project. We have
              the right licenses and insurance, and we're part of groups like
              the Better Business Bureau and the Cityof.
              <br />
              <br />
              Making you happy is the most important thing for us. We want to do
              even better than you expect in everything we do.
              <br />
              <br />
              Our goal is to give you good quality, finish on time, and always
              make you happy.
            </p>

            <CtaForm />
          </div>

          <figure className="right">
            <img src={header3} alt="Casas" />
          </figure>
        </article>
      </section>
    </header>
  );
};

export default Header;
