import React, { useRef } from "react";
import "./styles.css";

import { useNavigate } from "react-router-dom";

const Form = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();

  const scriptURL =
    "https://script.google.com/macros/s/AKfycbxQati6CYfYgLoRB2YAQwAVxs8NJVxqcg05Z5PD1Ayv2Ak8BlEboG0Bvr590f_VO7GguA/exec";

  const handleSubmit = (e) => {
    e.preventDefault();

    document.getElementById("submit").disabled = true;
    document.getElementById("submit").style.cursor = "default";
    document.getElementById("submit").style.opacity = 0.5;

    fetch(scriptURL, {
      method: "POST",
      body: new FormData(formRef.current),
    })
      .then(() => {
        document.getElementById("form").reset();
        navigate("/sucesso");
      })
      .catch((err) => console.log(err));
  };

  return (
    <form ref={formRef} name="google-sheet" onSubmit={handleSubmit} id="form">
      <div className="left">
        <label htmlFor="name">
          Complete Name:
          <input type="text" id="name" name="Complete Name" required />
        </label>

        <label htmlFor="email">
          Email Address:
          <input type="email" id="email" name="Email Address" required />
        </label>

        <label htmlFor="tel">
          Phone No:
          <input type="tel" id="tel" name="Phone No" required />
        </label>

        <label htmlFor="services">
          Services:
          <select id="services" name="Services" required>
            <option value="">Services</option>
            <option value="Gutters">Gutters</option>
            <option value="Roofing">Roofing</option>
            <option value="HardiPlank Siding">HardiPlank Siding</option>
            <option value="Exterior Painting">Exterior Painting</option>
            <option value="Patio covers">Patio covers</option>
            <option value="Sofit & Fascia">Sofit & Fascia</option>
            <option value="Sheetrock">Sheetrock</option>
            <option value="Concrete">Concrete</option>
          </select>
        </label>
      </div>

      <div className="right">
        <label htmlFor="servico">
          Question / Message?:
          <textarea
            name="Question / Message"
            id="servico"
            cols="30"
            rows="10"
          ></textarea>
        </label>

        <button type="submit" id="submit">
          Submit Now
        </button>
      </div>
    </form>
  );
};

export default Form;
