import "./styles.css";

const Success = () => {
  return (
    <header id="success">
      <div className="container">
        <h2 className="title">
          Thank you! Someone from our department will make contact with you
          soon.
        </h2>
      </div>
    </header>
  );
};

export default Success;
