import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CtaForm from "../../components/CTAForm";
import CtaFormLight from "../../components/CTAFormLight";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about1_4 from "../../assets/about1_4.png";
import about1_5 from "../../assets/about1_5.png";
import about1_6 from "../../assets/about1_6.png";
import about1_7 from "../../assets/about1_7.png";
import about2_1 from "../../assets/about2_1.png";
import about2_1Mobile from "../../assets/mobile/about2_1.png";
import about2_2 from "../../assets/about2_2.png";
import about2_2Mobile from "../../assets/mobile/about2_2.png";
import about2_3 from "../../assets/about2_3.png";
import about2_3Mobile from "../../assets/mobile/about2_3.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const about1 = [
  {
    id: 1,
    image: about1_1,
  },
  {
    id: 2,
    image: about1_2,
  },
  {
    id: 3,
    image: about1_3,
  },
  {
    id: 4,
    image: about1_4,
  },
  {
    id: 5,
    image: about1_5,
  },
  {
    id: 6,
    image: about1_6,
  },
  {
    id: 7,
    image: about1_7,
  },
];

const about2 = [
  {
    id: 1,
    image: about2_1,
    imageMobile: about2_1Mobile,
  },
  {
    id: 2,
    image: about2_2,
    imageMobile: about2_2Mobile,
  },
  {
    id: 3,
    image: about2_3,
    imageMobile: about2_3Mobile,
  },
];

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container vertical">
          <h2 className="title">Our Services.</h2>

          <p className="text1">
            We offer an extensive range of premium services for homeowners in
            your vicinity, including:
          </p>

          {/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          ) ? (
            <div className="mobile">
              {about1.map(({ id, image }) => {
                return (
                  <figure>
                    <img src={image} alt={`Exemplo ${id} de Foto`} />
                  </figure>
                );
              })}
            </div>
          ) : (
            <Swiper
              className="carousel"
              spaceBetween={20}
              slidesPerView={3}
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {about1.map(({ id, image }) => {
                return (
                  <SwiperSlide key={id} className="img">
                    <figure>
                      <img src={image} alt={`Exemplo ${id} de Foto`} />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}

          <p className="text2">
            Our commitment to excellence is reflected in our use of only the
            finest quality materials for every job we undertake. Your
            satisfaction and the longevity of our work are our top priorities,
            ensuring that you receive exceptional service and enduring results.
          </p>

          <CtaForm />
        </div>
      </article>

      <article className="about2">
        <div className="container vertical">
          <h2 className="title">What our customers say</h2>

          <Swiper
            className="carousel"
            spaceBetween={0}
            slidesPerView={1}
            navigation
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {about2.map(({ id, image, imageMobile }) => {
              return (
                <SwiperSlide key={id} className="img">
                  <figure>
                    <img
                      src={
                        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                          navigator.userAgent
                        )
                          ? imageMobile
                          : image
                      }
                      alt={`Exemplo ${id} de Foto`}
                    />
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <p className="text">
            You can also guarantee a quality service when customizing your home!
          </p>

          <CtaForm />
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <div className="left">
            <h2 className="title">
              Your Renovation <br /> <span> Starts Here</span>
            </h2>

            <p className="text">
              Each of our completed projects comes with a 1-20 year warranty,
              depending on the project, giving you peace of mind knowing that
              your home’s renovations are in good hands.
            </p>

            <CtaFormLight />
          </div>

          <div className="right">
            <h2 className="title">Why choose us?</h2>

            <p className="text">
              At JL Remodeling, we want to give you great quality work done in a
              reasonable time frame while offering the best prices for your
              pocket.
            </p>

            <div className="bottom">
              <p className="item">
                <span>100%</span>
                Guaranteed work
              </p>

              <p className="item">
                <span>+ 18</span>
                years of experience
              </p>
            </div>

            <CtaFormLight />
          </div>
        </div>
      </article>
    </section>
  );
};

export default About;
