import { useEffect, useState } from "react";
import "./styles.css";

import icon from "../../assets/icontel.png";

const CtaTel = (props) => {
  const [text, setText] = useState("CALL US TODAY!");
  const [tel, setTel] = useState("tel:504 305 0325");

  useEffect(() => {
    if (props.text !== undefined) {
      setText(props.text);
    }

    if (props.tel !== undefined) {
      setTel(props.tel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button id="ctaTel">
      <a href={tel}>
        <figure>
          <img src={icon} alt="Ícone do Telefone" />
        </figure>

        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaTel;
