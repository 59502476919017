import React from "react";
import "./styles.css";

import logo_rodape from "../../assets/logo_rodape.png";
import footer1_1 from "../../assets/footer1_1.png";
import footer1_2 from "../../assets/footer1_2.png";
import footer1_3 from "../../assets/footer1_3.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <figure className="logo">
            <img src={logo_rodape} alt="Logo" />
          </figure>

          <div className="item1">
            <p className="text1">Information</p>

            <p className="text2">
              Gutters in Louisiana, Residential and Commercial Gutter Systems,
              roofing in Louisiana.
            </p>
          </div>

          <div className="item2">
            <p className="text1">NEW ORLEANS, LA.</p>

            <div className="bottom">
              <div className="item">
                <figure>
                  <img src={footer1_1} alt="Localização" />
                </figure>

                <p className="text2">
                  8400 Old Gentily Road New Orleans, LA 70127
                </p>
              </div>

              <div className="item item2">
                <figure>
                  <img src={footer1_2} alt="Phone" />
                </figure>

                <p className="text2">
                  <a href="tel:504 305 0325">(504) 305 0325</a>
                </p>
              </div>

              <div className="item">
                <figure>
                  <img src={footer1_3} alt="Email" />
                </figure>

                <p className="text2">
                  <a href={"mailto:jl.remodeling@hotmail.com"}>
                    jl.remodeling@hotmail.com
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="item3">
            <p className="text1">DESTIN, FL.</p>

            <div className="bottom">
              <div className="item">
                <figure>
                  <img src={footer1_1} alt="Localização" />
                </figure>

                <p className="text2">
                  529 Kelly St, Destin, FL <br /> 32541
                </p>
              </div>

              <div className="item item2">
                <figure>
                  <img src={footer1_2} alt="Phone" />
                </figure>

                <p className="text2">
                  <a href="tel:850 738 5188">(850) 738 5188</a>
                </p>
              </div>

              <div className="item">
                <figure>
                  <img src={footer1_3} alt="Email" />
                </figure>

                <p className="text2">
                  <a href={"mailto:jl.remodeling@hotmail.com"}>
                    jl.remodeling@hotmail.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <p className="text">JL Remodeling LLC  © 2024 All rights reserved</p>

          <p className="text2">
            Developed by{" "}
            <a href="https://magencia.com.br" target="_blank" rel="noreferrer">
              M Agência Digital
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
